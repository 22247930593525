import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import KnaekPrimaryButton from "./KnaekPrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  updateEmailInModal,
  updateShowLoginLinkModal,
} from "../../redux/reduxActions/reduxStoreActions";
import { stringsTranslate } from "../../locales/CustomTranslater";
import { appWords } from "../../config/Constants";
import { validateEmail } from "../../uitls/DataValidator";
import { loginFunctionCall } from "../../api/ApiCalls";
import { hasAValue } from "../../uitls/SharedFunctions";

export default function LoginLinkComponent({timer,setTimer}) {
  const dispatch = useDispatch();
  const showLoginLinkModalRS = useSelector(
    (state) => state.showLoginLinkModalRS
  );
  const emailInModalRS = useSelector((state) => state.emailInModalRS);
  const [errorMessage, setErrorMessage] = useState(undefined);



  const handleButtonClick = () => {
    if (timer <= 0) {
      let data = {};
      data["email"] = emailInModalRS;

      if (validateEmail(emailInModalRS)) {
        loginFunctionCall(data)
          .then((result) => {
            setTimer(5 * 60);
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage(initString.error_message);
          });
      } else {
        // Handle validation errors here
        console.log("Data validation failed");
      }
    }
  };

  const initString = {
    headerLoginLink: stringsTranslate(appWords.login_link_component_title),
    bodyLoginLink: stringsTranslate(appWords.body_login_link),
    buttonText: stringsTranslate(appWords.button_login_link_text),
  };

  return (
    <Modal
      className="modal fade radius-15 mt-4"
      size={"lg"}
      show={showLoginLinkModalRS}
      onHide={() => {
        if (timer === 0) {
          dispatch(updateEmailInModal(null));
        }
        dispatch(updateShowLoginLinkModal(false));
        // dispatch(updateEmailInModal(null));
      }}
    >
      <div className="radius-15 bg-light" role="document">
        <div className="radius-15">
          <div className={"radius-15 pt-3 bg-light"}>
            <div
              className="card-header bg-light border-0 d-flex justify-content-end cursor-pointer"
              onClick={() => {
                dispatch(updateShowLoginLinkModal(false));
                if (timer === 0) {
                  dispatch(updateEmailInModal(null));
                }
              }}
            >
              <svg
                className=" mt-3 mr-3"
                width="15"
                height="15"
                viewBox="0 0 32 32"
              >
                <path
                  fill="black"
                  d="M31.29,27.48a2.7,2.7,0,0,1-3.81,3.81L16,19.83,4.52,31.29A2.7,2.7,0,0,1,.71,27.48L12.17,16,.71,4.52A2.7,2.7,0,0,1,4.52.71L16,12.17,27.48.71a2.7,2.7,0,0,1,3.81,3.81L19.83,16Z"
                />
              </svg>
            </div>
            <div className="modal-body  radius-15">
              <div className="card border-top border-0">
                <div className="card-header bg-light border-0 d-flex justify-content-center">
                  <h1
                    className="card-title text-center"
                    style={{ width: "90%" }}
                  >
                    {initString.headerLoginLink}
                  </h1>
                </div>

                <div className="card-body bg-light pb-0">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item radius-15 login-fields-div border-0 my-2">
                      <label className={"h2"} style={{ fontSize: "18px" }}>
                        {initString.bodyLoginLink}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {hasAValue(errorMessage) && (
              <label className={"my-3 ml-2 text-danger"}>{errorMessage}</label>
            )}
            <li
              className="d-flex order-0 order-lg-1 flex-wrap justify-content-center justify-content-lg-end align-items-center"
              style={{ marginBottom: "70px" }}
            >
              <div
                className={
                  "w-100 order-0 order-lg-1 d-flex justify-content-center"
                }
              >
                <KnaekPrimaryButton
                  title={
                    timer > 0
                      ? `${initString.buttonText} (${Math.floor(timer / 60)}:${
                          timer % 60 < 10 ? "0" : ""
                        }${timer % 60})`
                      : initString.buttonText
                  }
                  className={" mt-2 d-flex justify-content-center"}
                  onClick={handleButtonClick}
                  disabled={timer > 0}
                />
              </div>
            </li>
          </div>
        </div>
      </div>
    </Modal>
  );
}

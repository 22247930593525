import React, {useEffect, useState} from 'react';
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {formValidator, hasAValue} from "../../../uitls/SharedFunctions";
import {appWords} from "../../../config/Constants";
import {useDispatch, useSelector} from "react-redux";
import {updateOrderKnaekDataRS} from "../../../redux/reduxActions/reduxStoreActions";
import {
    checkTwoValuesAreEqual,
    validateEmail, validateEmailAndSubscription, validateStudentEmailAddressAndSubscription
} from "../../../uitls/DataValidator";
import ArrowOrderWizardComponent from "./ArrowOrderWizardComponent";
import {validationErrorAlertFunction} from "../../../uitls/AlertFunctions";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";



export default function OrderKnaekIDFlowStep0({currentStep, setCurrentStep}) {
    const orderKnaekDataRS = useSelector(state => state.orderKnaekDataRS);
    const dispatch = useDispatch()
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [wantToGetUpdates, setWantToGetUpdates] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);


    const initStrings = {
        where_do_you_study: stringsTranslate(appWords.where_do_you_study),
        netherlands: stringsTranslate(appWords.netherlands),
        belgium: stringsTranslate(appWords.belgium),
    }

    return (
        <section className="section align-items-start justify-content-start mt-5 w-100">
            <div className="container-fluid mt-5 ">
                <div className="row justify-content-center align-items-center h-100vh">
                    <div className="col-md-12 col-sm-12 ">

                        <div className={"text-left mt-2 mb-3"}>

                            <h2 className="mb-3 text-center">{initStrings.where_do_you_study}</h2>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <ul className="d-flex flex-wrap justify-content-start pl-0 ml-0">
                                    <li className="col-12 d-flex flex-wrap justify-content-around align-items-center">

                                        <KnaekPrimaryButton
                                            onClick={()=>{
                                                goToNL()
                                            }}
                                            className={"mt-2 mb-4 w-75"}
                                            title={(initStrings.netherlands).toString().toUpperCase()}
                                        />

                                    </li>

                                    <li className="col-12 d-flex flex-wrap justify-content-around align-items-center">

                                        <KnaekPrimaryButton
                                            onClick={()=>{
                                                goToBE()
                                            }}
                                            className={"mt-2 mb-4 w-75"}
                                            title={(initStrings.belgium).toString().toUpperCase()}
                                        />

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/*<ArrowOrderWizardComponent handleBack={() => handleBack()}*/}
                    {/*                           currentStep={currentStep}/>*/}
                </div>
            </div>
        </section>
    );

    function goToNL(){
        if(window.location.toString().toLowerCase().includes("knaek.nl")){
            handleNext()
        }else{
            window.location.href = "https://knaek.nl/bestellen?start=yes"
        }
    }

    function goToBE(){
        if(window.location.toString().toLowerCase().includes("knaek.be")){
            handleNext()
        }else{
            window.location.href = "https://knaek.be/bestellen?start=yes"
        }
    }

     function handleNext() {
        setCurrentStep((currentStep) => currentStep + 1);
    }



    function handleBack() {
        if(currentStep !== 1)
            setCurrentStep(currentStep-1)
    }
}

import React, {useEffect, useState} from 'react';
import FooterSection from '../../../components/FooterSection/Footer';
import {appUrls, appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import BreadcrumbRouteNav from "../../AppCustomizedComponent/BreadcrumbRouteNav";
import {dateFormat, hasAValue} from "../../../uitls/SharedFunctions";
import ImageComponent from "../../AppComponents/ImageComponent";
import {useDispatch, useSelector} from "react-redux";
import {updateShowAuthModalRS} from "../../../redux/reduxActions/reduxStoreActions";
import {useParams} from "react-router-dom";
import {alreadyAppliedForVacancyCall, applyVacancyJoppCall, viewVacancyJoppCall} from "../../../api/ApiCalls";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import ApplyJobComponent from "../../AppComponents/ApplyJobComponent";
import {validateHouseNumber, validatePhoneNumber, validatePostalCode} from "../../../uitls/DataValidator";
import {validationErrorAlertFunction} from "../../../uitls/AlertFunctions";
import {FaBuilding, FaClock, FaEuroSign, FaGraduationCap, FaMapMarkerAlt} from "react-icons/fa";


export default function JobDetailFirstSectionJobs({job}) {
    const initStrings = {
        applyButton: stringsTranslate(appWords.apply_button),
        sendButton: stringsTranslate(appWords.send_button),
        company_info: stringsTranslate(appWords.company_info),
        overview: stringsTranslate(appWords.overview),
        summary: stringsTranslate(appWords.summary),
        function_description: stringsTranslate(appWords.function_description),
        about_company: stringsTranslate(appWords.about_company),
        requirements: stringsTranslate(appWords.requirements),
        what_we_offer: stringsTranslate(appWords.what_we_offer),
        additional_info: stringsTranslate(appWords.additional_info),
        hours_per_week: stringsTranslate(appWords.hours_per_week),
        to: stringsTranslate(appWords.to),
        salary_per_hour: stringsTranslate(appWords.salary_per_hour),
    }

    const dispatch = useDispatch()
    const authDataRS = useSelector(state => state.authDataNEWRS);


    // Variables
    const [phone_number, setPhone_number] = useState(null)
    const [country_code, setCountry_code] = useState("+31")
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [agreeToUpdates, setAgreeToUpdates] = useState(false);


    const [intiVariables, setIntiVariables] = useState({})
    const [alreadyApplied, setAlreadyApplied] = useState(true)
    const [showApplyModal, setShowApplyModal] = useState(false)

    useEffect(() => {
        let intiVariablesLocal = {}
        if (hasAValue(job)) {
            intiVariablesLocal['id'] = job.id
            intiVariablesLocal['jobTitle'] = job.jobTitle
            intiVariablesLocal['jobSummary'] = job.jobSummary
            intiVariablesLocal['functionDescription'] = job.functionDescription
            intiVariablesLocal['aboutCompanyJob'] = job.aboutCompanyJob
            intiVariablesLocal['jobRequirements'] = job.jobRequirements
            intiVariablesLocal['jobOffer'] = job.jobOffer
            intiVariablesLocal['jobAdditionalInfo'] = job.jobAdditionalInfo
            intiVariablesLocal['jobMinHours'] = job.jobMinHours
            intiVariablesLocal['jobMaxHours'] = job.jobMaxHours
            intiVariablesLocal['jobMinSalary'] = job.jobMinSalary
            intiVariablesLocal['jobMaxSalary'] = job.jobMaxSalary
            intiVariablesLocal['postedAt'] = job.postedAt
            intiVariablesLocal['origin'] = job.origin
            intiVariablesLocal['company'] = job.company
            intiVariablesLocal['logo'] = hasAValue(job.logo)? job.logo : (job.origin.toString().includes("joppboard")? '/assets/images/Jopp/joppboard.png': '/assets/images/Jopp/Jopp-logo-500x500.jpg')
            intiVariablesLocal['background'] = hasAValue(job.background) ? job.background : '/assets/images/Jopp/jopp-banner-img3.png'
            intiVariablesLocal['job_url'] = job.job_url
            viewVacancy(job)
            alreadyAppliedForVacancy(job)
        }
        setIntiVariables(intiVariablesLocal)

    }, [job])


    return (
        <div className="p-0 m-0">
            <div className={" w-100 jobs-banner-img p-0 m-0"}>
                <BreadcrumbRouteNav element1={"bijbaan"} element2={intiVariables.company} element3={intiVariables.jobTitle} colorWhite={true}/>

            </div>
            {hasAValue(intiVariables) && (
                <section className={"job-content-card"}>
                    <div className="container">
                        <div
                            className="row justify-content-lg-between justify-content-xl-between justify-content-center ">
                            <div className="col-lg-7 col-11 bg-white shadow p-lg-5 p-3 py-5 radius-25">
                                <div className="row">

                                    <div className="col-lg-2 col-md-2 col-sm-12 d-none d-md-block">
                                        <ImageComponent src={intiVariables.logo}
                                                        className="col-lg-3 col-md-12 bg-white radius-15 store-discount-details-card-logo shadow"
                                                        alt="" height={98} width={98}
                                        />
                                    </div>

                                    <div className="col-lg-2 col-md-2 col-sm-12 d-flex justify-content-center d-block d-md-none logo-discount-mobile">
                                        <ImageComponent src={intiVariables.logo}
                                                        className="col-lg-3 col-md-12 bg-white radius-15 store-discount-details-card-logo shadow"
                                                        alt="" height={98} width={98}
                                        />
                                    </div>


                                    <div className="col-lg-9 col-md-9 col-sm-12 mt-lg-0 mt-3">
                                        <h2 className="mt-2 text-knaek-title ml-lg-3 ">{intiVariables.jobTitle}</h2>
                                        <div className="mt-2 divider-horizontal ml-lg-3"/>
                                        {hasAValue(intiVariables.subtitle) &&
                                            <h5 className="mt-2 text-knaek-primary ml-lg-3">{intiVariables.subtitle.value.toString()}</h5>}
                                    </div>
                                    {/*<p className="border-bottom mt-3 mb-2 ml-4 discount-description">{intiVariables.description}</p>*/}
                                </div>
                                {hasAValue(intiVariables) && <ul className="row mt-3">
                                    <li className={"col-12 my-2"}>
                                        <h5>{initStrings.summary}</h5>
                                        {/*{(hasAValue(intiVariables) &&*/}
                                        {/*    intiVariables.jobSummary &&*/}
                                        {/*    intiVariables.origin.toString().includes('cockpit'))?*/}
                                        {/*    <p dangerouslySetInnerHTML={{__html: intiVariables.jobSummary}}/>*/}
                                        {/*    :<p>{intiVariables.jobSummary}</p>}*/}
                                        {(hasAValue(intiVariables) && intiVariables.jobSummary && intiVariables.origin.toString().includes('joppboard')) && <p>{intiVariables.jobSummary}</p>}
                                        {(hasAValue(intiVariables) && intiVariables.jobSummary && intiVariables.origin.toString().includes('cockpit')) && <p dangerouslySetInnerHTML={{__html: intiVariables.jobSummary}} />}

                                    </li>
                                    <li className={"col-12 my-2"}>
                                        <h5>{initStrings.function_description}</h5>
                                        {(hasAValue(intiVariables) && intiVariables.functionDescription && intiVariables.origin.toString().includes('joppboard')) && <p>{intiVariables.functionDescription}</p>}
                                        {(hasAValue(intiVariables) && intiVariables.functionDescription && intiVariables.origin.toString().includes('cockpit')) && <p dangerouslySetInnerHTML={{__html: intiVariables.functionDescription}} />}

                                    </li>
                                    <li className={"col-12 my-2"}>
                                        <h5>{initStrings.about_company}</h5>
                                        {(hasAValue(intiVariables) && intiVariables.aboutCompanyJob && intiVariables.origin.toString().includes('joppboard')) && <p>{intiVariables.aboutCompanyJob}</p>}
                                        {(hasAValue(intiVariables) && intiVariables.aboutCompanyJob && intiVariables.origin.toString().includes('cockpit')) && <p dangerouslySetInnerHTML={{__html: intiVariables.aboutCompanyJob}} />}
                                    </li>
                                    <li className={"col-12 my-2"}>
                                        <h5>{initStrings.requirements}</h5>
                                        {(hasAValue(intiVariables) && intiVariables.jobRequirements && intiVariables.origin.toString().includes('joppboard')) && <p>{intiVariables.jobRequirements}</p>}
                                        {(hasAValue(intiVariables) && intiVariables.jobRequirements && intiVariables.origin.toString().includes('cockpit')) && <p dangerouslySetInnerHTML={{__html: intiVariables.jobRequirements}} />}

                                    </li>
                                    <li className={"col-12 my-2"}>
                                        <h5>{initStrings.what_we_offer}</h5>
                                        {(hasAValue(intiVariables) && intiVariables.jobOffer && intiVariables.origin.toString().includes('joppboard')) && <p>{intiVariables.jobOffer}</p>}
                                        {(hasAValue(intiVariables) && intiVariables.jobOffer && intiVariables.origin.toString().includes('cockpit')) && <p dangerouslySetInnerHTML={{__html: intiVariables.jobOffer}} />}


                                    </li>
                                    {hasAValue(intiVariables.jobAdditionalInfo) && <li className={"col-12 my-2"}>
                                        <h5>{initStrings.additional_info}</h5>

                                        {(hasAValue(intiVariables) && intiVariables.jobOffer && intiVariables.origin.toString().includes('joppboard')) && <p>{intiVariables.jobAdditionalInfo}</p>}
                                        {(hasAValue(intiVariables) && intiVariables.jobOffer && intiVariables.origin.toString().includes('cockpit')) && <p dangerouslySetInnerHTML={{__html: intiVariables.jobAdditionalInfo}} />}


                                    </li>}

                                </ul>}

                                {/*Buttons */}

                                <div className="d-flex flex-wrap justify-content-center justify-content-lg-start text-left mt-0 pt-3 pt-lg-5">
                                    <KnaekPrimaryButton
                                        className={`px-2`}
                                        onClick={() => {
                                            intiVariables.id.toString().toLowerCase().includes('publication')? setShowApplyModal(true) : window.open(intiVariables.job_url)
                                        }}
                                        title={initStrings.applyButton}/>

                                </div>
                            </div>

                            {/*Left elements */}
                            <div className="col-lg-4 col-11">
                                <ul className="row justify-content-center">
                                    <li className={"col-12 my-2 bg-white shadow p-5 m-5 radius-25"}>
                                        <h2 className={"mb-3"}> {initStrings.overview}</h2>
                                        <h5 className={"text-primary"}><FaBuilding
                                            className={"text-knaek-title h4 mr-2 job-icon-size"}/> {intiVariables.company}
                                        </h5>
                                        <h5 className={"text-primary"}><FaMapMarkerAlt
                                            className={"text-knaek-title h4 mr-2 job-icon-size"}/> Amsterdam</h5>
                                        <h5 className={"text-primary"}><FaClock
                                            className={"text-knaek-title h4 mr-2 job-icon-size"}/> {intiVariables.jobMinHours + initStrings.to + intiVariables.jobMaxHours} {initStrings.hours_per_week}</h5>
                                        <h5 className={"text-primary"}><FaEuroSign
                                            className={"text-knaek-title h4 mr-2 job-icon-size"}/> {intiVariables.jobMinSalary + " - " + intiVariables.jobMaxSalary} euro</h5>
                                        <h5 className={"text-primary"}><FaGraduationCap
                                            className={"text-knaek-title h4 mr-2 job-icon-size"}/> MBO/HBO/WO</h5>
                                    </li>

                                    <li className={"col-12 d-flex pt-5 ml-lg-4  ml-0 pl-0  pt-3 pt-lg-5 radius-25 justify-content-center justify-content-lg-start"}>

                                        <KnaekPrimaryButton
                                            className={`px-2`}
                                            onClick={() => {
                                                intiVariables.id.toString().toLowerCase().includes('publication')? setShowApplyModal(true) : window.open(intiVariables.job_url)
                                            }}
                                            title={initStrings.applyButton}/>


                                    </li>

                                </ul>

                            </div>
                        </div>
                    </div>
                </section>
            )}
            <ApplyJobComponent phone_number={phone_number} country_code={country_code}
                               name={name} agreeToTerms={agreeToTerms}
                               email={email}
                               agreeToUpdates={agreeToUpdates}
                               setPhone_number={(val) => setPhone_number(val)}
                               setAgreeToTerms={(val) => setAgreeToTerms(val)}
                               setAgreeToUpdates={(val) => setAgreeToUpdates(val)}
                               setCountry_code={(val) => setCountry_code(val)}
                               setEmail={(val) => setEmail(val)}
                               setName={(val) => setName(val)}
                               applyVacancy={() => applyVacancy()}
                               job={job} showModal={showApplyModal}
                               setShowModal={(val) => setShowApplyModal(val)}/>
        </div>
    );

    async function viewVacancy(itemLocal) {
        let data = {id: itemLocal.id}
        await viewVacancyJoppCall(data).then(response => {
        }).catch(error => {
            console.log(error.data)
        })
    }


    async function alreadyAppliedForVacancy(itemLocal) {
        let data = {id: itemLocal.id}
        await alreadyAppliedForVacancyCall(data).then(response => {
            setAlreadyApplied(response)
        }).catch(error => {
            console.log(error.data)
        })
    }

    function applyVacancy() {
        let data = {id: job.id, phone_number, country_code, email, name}
        if (validatePhoneNumber(country_code, phone_number) && hasAValue(name) && agreeToTerms && agreeToUpdates) {
            applyVacancyJoppCall(data).then(response => {
            }).catch(error => {
                console.log(error)
            })
            alreadyAppliedForVacancy(job).then(() => {

            })
            setShowApplyModal(false)
        } else {
            if (!validatePhoneNumber(country_code, phone_number)) {
                validationErrorAlertFunction( stringsTranslate(appWords.error_phone_number_not_good))
            }
        }

    }
}

import React, {useEffect, useState} from 'react';
import {appUrls, appWords} from "../../../config/Constants";
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import BreadcrumbRouteNav from "../../AppCustomizedComponent/BreadcrumbRouteNav";
import {dateFormat, hasAValue} from "../../../uitls/SharedFunctions";
import ImageComponent from "../../AppComponents/ImageComponent";
import {useDispatch, useSelector} from "react-redux";
import {updateShowAuthModalRS} from "../../../redux/reduxActions/reduxStoreActions";
import {useParams} from "react-router-dom";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import KnaekSecondaryButton from "../../AppComponents/KnaekSecondaryButton";
import {Helmet} from "react-helmet";

export default function DiscountDetailFirstSectionStoreDiscounts({storeDiscount}) {
    let initStrings = {
        load_more_button:stringsTranslate(appWords.load_more_button),
        order_your_knaek_id:stringsTranslate(appWords.order_your_knaek_id),
        students_discounts:stringsTranslate(appWords.students_discounts),
        expiration_date:stringsTranslate(appWords.expiration_date),
        valid_until:stringsTranslate(appWords.valid_until),
        participating_locations:stringsTranslate(appWords.participating_locations),
        contact_title_1:stringsTranslate(appWords.contact_title_1),
        open_knaek_app: stringsTranslate(appWords.open_knaek_app)
    }
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    const locationsRS = useSelector(state => state.locationsRS);
    const authDataRS = useSelector(state => state.authDataNEWRS);
    const dispatch = useDispatch()
    const {city_name, name, id} = useParams()
    const [numItemsToShowLocations, setNumItemsToShowLocations] = useState(5);
    let contentLanguage = appLanguageRS === "en"? "en": "nl"



    const [intiVariables, setIntiVariables] = useState({})

    let language = appLanguageRS
    // const intiStrings = {}
    useEffect(() => {
        let intiVariablesLocal = {}

        if (hasAValue(storeDiscount)) {
            intiVariablesLocal['image'] = appUrls.image_url + storeDiscount.imageId
            intiVariablesLocal['icon'] = appUrls.image_url + storeDiscount.iconId
            intiVariablesLocal['title'] = storeDiscount.localization[contentLanguage].title
            intiVariablesLocal['subtitle'] = storeDiscount.localization[contentLanguage].subtitle
            intiVariablesLocal['description'] = storeDiscount.localization[contentLanguage].description
            intiVariablesLocal['validUntill'] = dateFormat(storeDiscount.validUntill)
            intiVariablesLocal['locations'] = locationsRS
                .filter(item => parseInt(item.companyId) === parseInt(storeDiscount.companyId))
                .sort((a, b) => a.city.localeCompare(b.city));

        }
        setIntiVariables(intiVariablesLocal)

    }, [storeDiscount,appLanguageRS])


    return (
        <div className="p-0 m-0">
            {hasAValue(storeDiscount) && (
                <div className="container">
                    <Helmet>
                        <title>{`Studentenkorting-${intiVariables?.title}`}</title>
                        <link rel="icon" href={intiVariables.icon} type="image/png" sizes="16x16"/>
                    </Helmet>
                    <BreadcrumbRouteNav element1={"Studentenkorting"} element2={city_name} element3={intiVariables?.title}/>
                    <div className="row ">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <ImageComponent src={intiVariables.image} alt={`studentenkorting-${intiVariables?.title}`}/>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-12 d-none d-md-block">
                                    <ImageComponent src={intiVariables.icon}
                                                    className="col-lg-3 col-md-12 bg-white radius-15 store-discount-details-card-logo shadow"
                                                    alt="" height={98} width={98}
                                    />
                                </div>

                                <div className="col-lg-2 col-md-2 col-sm-12 d-flex justify-content-center d-block d-md-none logo-discount-mobile">
                                    <ImageComponent src={intiVariables.icon}
                                                    className="col-lg-3 col-md-12 bg-white radius-15 store-discount-details-card-logo shadow"
                                                    alt="" height={98} width={98}
                                    />
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-12 title-position">
                                    <h4 className="mt-2 text-knaek-title ml-lg-4">{initStrings.students_discounts} {intiVariables.title}</h4>
                                    <div className="mt-2 divider-horizontal ml-lg-4"/>
                                    <p className="mt-3 text-knaek-primary font-weight-bold ml-lg-4">{intiVariables.subtitle}</p>
                                </div>
                                <p className="mt-3 mb-2 ml-4 discount-description">{intiVariables.description}</p>
                            </div>
                            <div className="row ml-2 mr-2 mt-2">
                                <div className="col-12 col-lg-12 border-bottom border-top d-flex justify-content-between p-0">
                                    <p className="font-weight-bold mt-2 mb-2 pl-0 ml-0">{initStrings.expiration_date}</p>
                                    <p className="text-knaek-primary mt-2 mb-2">{initStrings.valid_until}:  {intiVariables.validUntill}</p>
                                </div>

                                <h6 className="mt-3">{initStrings.participating_locations}</h6>

                                <h5 className="col-12 m-0 justify-content-start text-white pt-3 pb-3 dark-background">{initStrings.contact_title_1}</h5>
                                {intiVariables.locations &&
                                    intiVariables.locations.slice(0, numItemsToShowLocations).map((item, i) => (
                                        <div className="mt-3 w-100 d-flex flex-wrap justify-content-between" key={i}>
                                            <p className={"text-left col-4"}>{item.city}</p>
                                            <p className={"col-8 text-right"}>{item.address}</p>
                                        </div>
                                    ))}
                                <div className={"col-12 d-flex justify-content-center "}>
                                    {intiVariables.locations && intiVariables.locations.length > numItemsToShowLocations && (
                                        <KnaekSecondaryButton
                                            title={initStrings.load_more_button}
                                            className={"mt-4"}
                                            onClick={() => handleLoadMoreLocations()}
                                        />
                                    )}
                                </div>

                               </div>


                            <div className="row d-flex flex-wrap justify-content-center text-center">
                                <div className="col-12 col-lg-12 col-md-12">
                                {/*Buttons */}
                                {!hasAValue(authDataRS) &&
                                    <div className="row d-flex flex-wrap justify-content-center text-center mt-5">
                                        <div className="col-12 col-lg-12 col-md-12">
                                            <KnaekPrimaryButton
                                                title={initStrings.order_your_knaek_id}
                                                className={""}
                                                onClick={()=> window.location.href = "/bestellen"}
                                            />
                                        </div>
                                    </div>}
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            )}

            <div className="between-element-online-discount col-12 d-flex align-items-center justify-content-center">
                <KnaekPrimaryButton
                    title={initStrings.open_knaek_app.toString().toUpperCase()}
                    onClick={handleButtonClick}
                />
            </div>
        </div>
    );

    function handleLoadMoreLocations() {
        setNumItemsToShowLocations(numItemsToShowLocations + 5);
    }


    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function handleButtonClick() {
        if (isMobileDevice()) {
            // Attempt to open the app using a custom URI scheme.
            // Note: you'll need to configure your app to handle this scheme.
            window.location.href = 'knaekapp://';

            // If the app doesn't open within 2 seconds, navigate to Knaek
            setTimeout(() => {
                if (!document.hidden) {
                    window.location.href = "/download-app";
                }
            }, 2000);
        } else {
            // Navigate directly to Knaek for non-mobile devices
            window.location.href = "/download-app";
        }
    }
}

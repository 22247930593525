import i18n from 'i18n-js';

import translations from './translations.json';

const en = {};
const nl = {};

Object.keys(translations).forEach(key => {
    en[key] = translations[key].en;
    nl[key] = translations[key].nl;
});

i18n.translations = {
    en: en,
    nl: nl,
}

i18n.fallbacks = {languageTag: "en", isRTL: false};

export function stringsTranslate(name) {
    return i18n.t(name);
}

import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import KnaekPrimaryButton from "./KnaekPrimaryButton";
import {createSnipTechTicketCall, getAllSnipTechOnlineDiscountsCall} from "../../api/ApiCalls";
import {hasAValue} from "../../uitls/SharedFunctions";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {failedAlertFunction, successAlertFunction} from "../../uitls/AlertFunctions";

export default function ClaimCashbackComponent({onClose}) {
    const [searchQuery, setSearchQuery] = useState('');
    const [stores, setStores] = useState([]);
    const [storeSelected, setStoreSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const [jsonData, setJsonData] = useState({
        store: '',
        orderNumber: '',
        purchaseDate: '',
        purchaseAmount: '',
        remarks: '',
        file: '',
    });
    const [errorMessage, setErrorMessage] = useState(undefined);

    useEffect(() => {
        if (storeSelected) {
            setStores([]);
        } else {
            getShortSnipTechOnlineDiscounts();
        }
    }, [searchQuery]);

    useEffect(() => {
        if (storeSelected) {
            setStores([]);
        }
    }, [storeSelected]);

    const initString = {
        claimCashback: (stringsTranslate(appWords.claimCashback)).toString().toUpperCase(),
        claimCashbackInfo: stringsTranslate(appWords.claimCashbackInfo),
        claimCashbackInfoConditions: (stringsTranslate(appWords.claimCashbackInfoConditions)).toString().toUpperCase(),
        claimCashbackInfoConditions1: stringsTranslate(appWords.claimCashbackInfoConditions1),
        claimCashbackInfoConditions2: stringsTranslate(appWords.claimCashbackInfoConditions2),
        claimCashbackInfoConditions3: stringsTranslate(appWords.claimCashbackInfoConditions3),
        claimCashbackInfoConditions4: stringsTranslate(appWords.claimCashbackInfoConditions4),
        claimCashbackInfoConditions5: stringsTranslate(appWords.claimCashbackInfoConditions5),
        claimCashbackInfoConditions6: stringsTranslate(appWords.claimCashbackInfoConditions6),
        claimCashbackInfoConditions7: stringsTranslate(appWords.claimCashbackInfoConditions7),
        onlineStore: stringsTranslate(appWords.onlineStore),
        onlineStorePlaceholder: stringsTranslate(appWords.onlineStorePlaceholder),
        orderNumber: stringsTranslate(appWords.orderNumber),
        orderNumberPlaceholder: stringsTranslate(appWords.orderNumberPlaceholder),
        purchaseDate: stringsTranslate(appWords.purchaseDate),
        purchaseAmount: stringsTranslate(appWords.purchaseAmount),
        purchaseAmountPlaceholder: stringsTranslate(appWords.purchaseAmountPlaceholder),
        remarks: stringsTranslate(appWords.remarks),
        remarksPlaceholder: stringsTranslate(appWords.remarksPlaceholder),
        claimFormMandatoryFieldsErrorMessage: stringsTranslate(appWords.claimFormMandatoryFieldsErrorMessage),
        claimFormSuccessfulSubmission: stringsTranslate(appWords.claimFormSuccessfulSubmission),
        claimFormFailedSubmission: stringsTranslate(appWords.claimFormFailedSubmission),
        upload_the_email_confirmation_you_received_from_the_online_store: stringsTranslate(appWords.upload_the_email_confirmation_you_received_from_the_online_store),
        upload_the_email_confirmation_you_received_from_the_online_store1: stringsTranslate(appWords.upload_the_email_confirmation_you_received_from_the_online_store1),
        upload_the_email_confirmation_you_received_from_the_online_store2: stringsTranslate(appWords.upload_the_email_confirmation_you_received_from_the_online_store2),
        only_PDF_or_JPG_files_can_be_uploaded: stringsTranslate(appWords.only_PDF_or_JPG_files_can_be_uploaded),
        uploading_the_file_is_required: stringsTranslate(appWords.uploading_the_file_is_required),
    }

    return (
        <Modal className="modal fade radius-15 mt-4" id={"openClaim"} size={"lg"} onHide={onClose} show={true}>
            <div className="radius-15" role="document">
                <div className="radius-15">
                    <div className={"radius-15 pt-3 bg-light"}>
                        <div className="card-header bg-light border-0 d-flex justify-content-end cursor-pointer"
                             onClick={onClose}>
                            <svg className=" mt-3 mr-3" width="15" height="15" viewBox="0 0 32 32">
                                <path fill="black"
                                      d="M31.29,27.48a2.7,2.7,0,0,1-3.81,3.81L16,19.83,4.52,31.29A2.7,2.7,0,0,1,.71,27.48L12.17,16,.71,4.52A2.7,2.7,0,0,1,4.52.71L16,12.17,27.48.71a2.7,2.7,0,0,1,3.81,3.81L19.83,16Z"/>
                            </svg>
                        </div>
                        <div className="modal-body radius-15">
                            <div className="card border-top border-0">
                                <div className="card-header bg-light border-0 d-flex justify-content-center">
                                    <h1 className="card-title text-center">{initString.claimCashback}</h1>
                                </div>
                                <div className="bg-light text-center justify-content-center align-content-center">
                                    <p className="mr-5 ml-5">
                                        {initString.claimCashbackInfo}
                                    </p>
                                </div>
                                <div className="card-header bg-light border-0 d-flex justify-content-center pt-5">
                                    <h1 className="card-title text-center">{initString.claimCashbackInfoConditions}</h1>
                                </div>
                                <div className="bg-light text-center justify-content-center align-content-center">
                                    <p className="mr-4 ml-4 text-center">
                                        {initString.claimCashbackInfoConditions1}
                                    </p>
                                    <p className="mr-4 ml-4 pt-3 text-center">
                                        {initString.claimCashbackInfoConditions2}
                                    </p>
                                    <p className="mr-4 ml-4 pt-3 text-center">
                                        {initString.claimCashbackInfoConditions3}
                                    </p>
                                    <p className="mr-4 ml-4 pt-3 text-center">
                                        {initString.claimCashbackInfoConditions4}
                                    </p>
                                    <p className="mr-4 ml-4 pt-3 text-center">
                                        {initString.claimCashbackInfoConditions5}
                                    </p>
                                    <p className="mr-4 ml-4 pt-3 text-center">
                                        {initString.claimCashbackInfoConditions6}
                                    </p>
                                    <p className="mr-4 ml-4 pt-3 text-center">
                                        {initString.claimCashbackInfoConditions7}
                                    </p>

                                </div>

                                <form action={submitForm}>

                                    <ul className="card-body bg-light pb-0">
                                    {hasAValue(errorMessage) &&
                                            <label className={"my-3 ml-2 text-danger"}>{errorMessage} *</label>}
                                        <li className="list-group-item radius-15 border-0 my-2">
                                            <label className="knaek-form-label"
                                                   htmlFor="orderNumber">{initString.onlineStore} *
                                            </label>

                                            <input
                                                type="text"
                                                id="store"
                                                className="form-control bg-transparent shadow-none p-0"
                                                style={{height: "30px"}}
                                                name="store"
                                                placeholder={initString.onlineStorePlaceholder}
                                                value={searchQuery}
                                                onChange={(e) => {
                                                    setSearchQuery(e.target.value);
                                                    setStoreSelected(false);
                                                    setIsLoading(true); // Set isLoading to true when search query changes
                                                }}
                                            />
                                            {isLoading && (
                                                <div className="text-center m-2">
                                                    <div className="spinner-border text-knaek-secondary">
                                                    </div>
                                                </div>
                                            )}
                                            <ul className="list-group"
                                                style={{maxHeight: '300px', overflowY: 'auto'}}>
                                                {stores.map((store) => (
                                                    <div>
                                                        <li
                                                            key={store.id}
                                                            onClick={() => handleSelectedStore(store)}
                                                            className="py-3 list-group-item-action"
                                                            style={{cursor: 'pointer'}}
                                                        >
                                                            {store.title}
                                                        </li>
                                                        <hr className="border-top my-0"/>
                                                    </div>

                                                ))}
                                            </ul>

                                        </li>

                                        <li className="list-group-item radius-15 border-0 my-2">
                                            <label className="knaek-form-label"
                                                   htmlFor="orderNumber">{initString.orderNumber} *</label>
                                            <input
                                                type="text"
                                                id="orderNumber"
                                                className="form-control bg-transparent shadow-none p-0"
                                                style={{height: "30px"}}
                                                name="orderNumber"
                                                placeholder={initString.orderNumberPlaceholder}
                                                value={jsonData.orderNumber}
                                                onChange={(e) => updateJsonData({orderNumber: e.target.value})}
                                            />
                                        </li>

                                        <li className="list-group-item radius-15 border-0 my-2">
                                            <label className="knaek-form-label"
                                                   htmlFor="purchaseDate">{initString.purchaseDate} *</label>
                                            <input
                                                type="date"
                                                id="purchaseDate"
                                                className="form-control bg-transparent shadow-none p-0"
                                                style={{height: "30px"}}
                                                name="purchaseDate"
                                                value={jsonData.purchaseDate}
                                                onChange={(e) => updateJsonData({purchaseDate: e.target.value})}
                                            />
                                        </li>

                                        <li className="list-group-item radius-15 border-0 my-2">
                                            <label className="knaek-form-label"
                                                   htmlFor="purchaseAmount">{initString.purchaseAmount} *</label>
                                            <input
                                                type="text"
                                                id="purchaseAmount"
                                                className="form-control bg-transparent shadow-none p-0"
                                                style={{height: "30px"}}
                                                name="purchaseAmount"
                                                placeholder={initString.purchaseAmountPlaceholder}
                                                value={jsonData.purchaseAmount}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    // Allow numbers, a single dot, or a single comma
                                                    const isValidInput = /^(\d+([.,]\d*)?)?$/.test(value);
                                                    if (isValidInput) {
                                                        // Replace comma with dot for internal use, if necessary
                                                        const normalizedValue = value.replace(',', '.');
                                                        updateJsonData({purchaseAmount: normalizedValue});
                                                    }
                                                }}
                                            />
                                        </li>

                                        <li className="list-group-item radius-15 border-0 my-2">
                                            <label className="knaek-form-label"
                                                   htmlFor="remarks">{initString.remarks} *</label>
                                            <textarea
                                                id="remarks"
                                                className="form-control bg-transparent shadow-none p-0"
                                                name="remarks"
                                                value={jsonData.remarks}
                                                placeholder={initString.remarksPlaceholder}
                                                style={{height: "50px"}}
                                                onChange={(e) => updateJsonData({remarks: e.target.value})}
                                            />
                                        </li>

                                        <li className="list-group-item radius-15 border-0 my-2">
                                            <label className="knaek-form-label" htmlFor="confirmationEmail">
                                                {initString.upload_the_email_confirmation_you_received_from_the_online_store}
                                                <br/>
                                                <br/>
                                                {initString.upload_the_email_confirmation_you_received_from_the_online_store1}
                                                <br/>
                                                <br/>
                                                {initString.upload_the_email_confirmation_you_received_from_the_online_store2}
                                                <br/>
                                                {initString.only_PDF_or_JPG_files_can_be_uploaded}
                                                <br/>
                                                <br/>
                                                <a className={"ml-2 text-info"}> {initString.uploading_the_file_is_required} *</a>
                                            </label>

                                            <div className="upload-container">
                                                <label htmlFor="file" className="upload-label">
                                                    <i className="fa fa-paperclip"></i>
                                                    <a className={"ml-2"}> {fileName}</a>
                                                </label>

                                                <input
                                                    required
                                                    type="file"
                                                    id="file"
                                                    name="file"
                                                    accept=".pdf, .jpg, .png"
                                                    style={{display: 'none'}}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        </li>


                                        <li className="bg-light d-flex flex-wrap justify-content-center align-items-center">
                                            <div
                                                className={" border-0 order-0 order-lg-1 d-flex justify-content-center pt-3 pb-3"}>
                                                <KnaekPrimaryButton
                                                    title={initString.claimCashback}
                                                    className={" mt-2"}
                                                    onClick={submitForm}
                                                />
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );

    function handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            updateJsonData({file: file});
        } else {
            // Update the formData state to reflect no file is selected
            updateJsonData({file: ''});
        }
    }

    function updateJsonData(value = {}) {
        setJsonData({...jsonData, ...value});
        setErrorMessage(undefined);
    }

    function handleSelectedStore(store) {
        updateJsonData({store: store.id});
        setSearchQuery(store.title);
        setStoreSelected(true);
    }

    function getFilter() {
        const apiFilters = [];
        if (searchQuery?.length) {
            apiFilters.push(`nameWildcard=${encodeURIComponent(searchQuery)}`);
        }
        return apiFilters.join('&');
    }

    function getShortSnipTechOnlineDiscounts() {
        if (!searchQuery.length) {
            setStores([]);
            setIsLoading(false); // Stop loading when there's no search query
            return;
        }
        getAllSnipTechOnlineDiscountsCall(1, 20, getFilter()).then(data => {
            setStores(data.items);
            setIsLoading(false); // Stop loading once data is fetched
        }).catch(error => {
            // Handle error
            setIsLoading(false); // Stop loading in case of an error
        });
    }

    function appendJsonToFormData(formData, jsonData) {
        for (const key in jsonData) {
            formData.append(key, jsonData[key]);
        }
    }

    function submitForm(e) {
        e.preventDefault();
        if (Object.values(jsonData).some(value => !value)) {
            setErrorMessage(initString.claimFormMandatoryFieldsErrorMessage);
            return;
        }
        jsonData.purchaseAmount = parseFloat(jsonData.purchaseAmount);

        const formData = new FormData();
        appendJsonToFormData(formData, jsonData);

        onClose();
        createSnipTechTicketCall(formData).then(_ => {
            successAlertFunction(initString.claimCashback, initString.claimFormSuccessfulSubmission);
        }).catch(() => {
            failedAlertFunction(initString.claimFormFailedSubmission, '');
        });
    }
}

import React, {useEffect} from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {appWords} from "../../config/Constants";
import {loginWithOneTimeTokenCall} from "../../api/ApiCalls";
import {useParams} from "react-router-dom";
import {tokenErrorAlertFunction} from "../../uitls/AlertFunctions";
import {Spinner} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {updateAuthDataNEWRS, updateAuthDataRS} from "../../redux/reduxActions/reduxStoreActions";


export default function ToProfileAfterLogin() {
    const dispatch = useDispatch();
    const {token} = useParams();

    useEffect(() => {
        oneTimeTokenLogin()
    }, [])

    return (
        <div className="d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
            <Spinner animation="border" variant="primary"/>
        </div>
    );

    async function oneTimeTokenLogin() {
        function invalidLinkFeedback() {
            tokenErrorAlertFunction(stringsTranslate(appWords.link_expired), "")
            setTimeout(() => {
                window.location.href = "/"
            }, 3000);
        }

        try {
            const response = await loginWithOneTimeTokenCall({token});
            if (!response) {
                invalidLinkFeedback();
                return;
            }
            dispatch(updateAuthDataNEWRS({
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                city_id: response.data.city_id,
            }));
            setTimeout(() => {
                if(response.data?.goal?.toString().includes("APP_TO_WEB_REDIRECTION")){
                    window.location.href = "/account/online-bespaard#openClaim"
                }else{
                    window.location.href = "/"
                }
            }, 500);

        } catch (e) {
            invalidLinkFeedback();
        }
    }
}

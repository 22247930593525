import React, {useState, useEffect} from 'react';
import KnaekPrimaryButton from "../../AppComponents/KnaekPrimaryButton";
import {appWords} from "../../../config/Constants";
import {stringsTranslate} from "../../../locales/CustomTranslater";
import {successAlertFunction} from "../../../uitls/AlertFunctions";
import {postContactMailCall} from "../../../api/ApiCalls";


export default function ContactForm() {


    const [name, setName] = useState(undefined);
    const [number, setNumber] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const [subject, setSubject] = useState('betalen');
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        const isEmailValid = email => {
            // Regular expression for email validation
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return pattern.test(email);
        };

        // Check if all required fields are filled and email is valid
        const isFormValid = name && isEmailValid(email) && subject && message;
        setDisableButton(!isFormValid);
    }, [name, email, subject, message]);

    const initStrings = {

        formHeading: stringsTranslate(appWords.contact_form_heading),
        labelText: stringsTranslate(appWords.contact_label_1_text),
        labelText1: stringsTranslate(appWords.contact_label_2_text),
        labelText2: stringsTranslate(appWords.contact_label_3_text),
        labelText3: stringsTranslate(appWords.contact_label_4_text),
        labelText4: stringsTranslate(appWords.contact_label_5_text),
        optionVal: stringsTranslate(appWords.contact_option_1_val),
        optionVal1: stringsTranslate(appWords.contact_option_2_val),
        optionVal2: stringsTranslate(appWords.contact_option_3_val),
        optionVal3: stringsTranslate(appWords.contact_option_4_val),
        optionVal4: stringsTranslate(appWords.contact_option_5_val),
        optionVal5: stringsTranslate(appWords.contact_option_6_val),
        optionVal6: stringsTranslate(appWords.contact_option_7_val),
        optionVal7: stringsTranslate(appWords.contact_option_8_val),
        optionVal8: stringsTranslate(appWords.contact_option_9_val),
        optionVal9: stringsTranslate(appWords.contact_option_10_val),
        optionVal10: 'Jopp Studentes & Graduates',
        placeholder1: stringsTranslate(appWords.placeholder_text_your_name),
        placeholder2: stringsTranslate(appWords.placeholder_text_your_email),
        placeholder3: stringsTranslate(appWords.placeholder_text_ask_your_question),
        btnText: stringsTranslate(appWords.contact_btn_text)

    }


    return (
        <section id="contact-form" className="section bg-gray pb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-11 mt-5">
                        <div>
                            <ul className="list-group list-group-flush">
                                <h1 className="col-12 contact-title-faq mt-5">{initStrings.formHeading}</h1>

                                <li className="list-group-item radius-15 contact-form-fields-div border-0 my-2">
                                    <label>{initStrings.labelText}  *</label>
                                    <input name="name" type="text"
                                           className="form-control bg-transparent shadow-none"
                                           placeholder={initStrings.placeholder1}
                                           value={name}
                                           onChange={(val) => {setName(val.target.value)}}
                                    />
                                </li>
                                <li className="list-group-item radius-15 contact-form-fields-div border-0 my-2">
                                    <label>{initStrings.labelText1}</label>
                                    <input name="number" type="text"
                                           className="form-control bg-transparent shadow-none"
                                           placeholder="0612345678"
                                           value={number}
                                           onChange={(val) => {setNumber(val.target.value)}}
                                    />
                                </li>
                                <li className="list-group-item radius-15 contact-form-fields-div border-0 my-2">
                                    <label>{initStrings.labelText2}  *</label>
                                    <input name="email" type="email"
                                           className="form-control bg-transparent shadow-none"
                                           placeholder={initStrings.placeholder2}
                                           value={email}
                                           onChange={(val) => {setEmail(val.target.value)}}
                                    />
                                </li>
                                <li className="list-group-item radius-15 contact-form-fields-div border-0 my-2">
                                    <label>{initStrings.labelText3}  *</label>
                                    <select name="subject"
                                            className="form-control text-primary bg-white contact-form-selector-fields-div bg-transparent shadow-none custom-bg-selector"
                                            placeholder="Betaaln"
                                            value={subject}
                                            onChange={(val) => {setSubject(val.target.value)}}
                                    >
                                        <option className={"bg-white"} value="betalen">{initStrings.optionVal}</option>
                                        <option className={"bg-white"}  value="cashback_niet_geregistreerd">{initStrings.optionVal1}</option>
                                        <option className={"bg-white"}  value="cashback_algemeen">{initStrings.optionVal2}</option>
                                        <option className={"bg-white"}  value="opzegen">{initStrings.optionVal3}</option>
                                        <option className={"bg-white"}  value="sponsoring">{initStrings.optionVal4}</option>
                                        <option className={"bg-white"}  value="studentenkorting_geven">{initStrings.optionVal5}</option>
                                        <option className={"bg-white"}  value="knaek_id_niet_ontvangen">{initStrings.optionVal6}</option>
                                        <option className={"bg-white"}  value="knaek_box">{initStrings.optionVal7}</option>
                                        <option className={"bg-white"}  value="apply_knaek">{initStrings.optionVal9}</option>
                                        <option className={"bg-white"} value="knaek_id_is_al_gebruikt">{initStrings.optionVal8}</option>
                                        <option className={"bg-white"} value="jopp_studentes_and_graduates">{initStrings.optionVal10}</option>
                                    </select>
                                </li>
                                <li className="list-group-item radius-15 contact-form-text-area border-0 my-2">
                                    <label>{initStrings.labelText4}  *</label>
                                    <textarea name="message"
                                              className="form-control bg-transparent contact-form-textarea-fields shadow-none pl-0"
                                              placeholder={initStrings.placeholder3}
                                              value={message}
                                              onChange={(val) => {setMessage(val.target.value)}}
                                    />
                                </li>

                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-5 d-flex justify-content-center justify-content-lg-start  justify-content-md-start">
                                    <KnaekPrimaryButton title={initStrings.btnText}
                                                        disabled={disableButton}
                                                        className={"mt-2 justify-content-center shadow mb-5 border-0 "}
                                                        onClick={() => {postContactMail()}}
                                    />
                                </div>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );

    function postContactMail() {
        setDisableButton(true)
        let data = {}
        data['name'] = name
        data['number'] = number
        data['message'] = message
        data['email'] = email
        data['subject'] = subject
        postContactMailCall(data)
            .then(result => {
                setEmail('');
                setName('')
                setSubject('betalen')
                setNumber('')
                setMessage('')
                successAlertFunction("", stringsTranslate(appWords.alert_after_submit_contact_form));
            })
            .catch(error => {
                setDisableButton(false)
                console.log("postContactMailCall error ", error)
            });
    }
}

import {getDataLocalStorage, saveDataLocalStorage} from "./LocalStorageFunctions";
import {
    appLocalStoargeValues,
    joppStockImagesAdministrative,
    joppStockImagesCateringIndustry,
    joppStockImagesCustomerService,
    joppStockImagesDelivery,
    joppStockImagesLogistics,
    joppStockImagesMarketingAndSales,
    joppStockImagesOthers,
    joppStockImagesRetail
} from "../config/Constants";
import {validateEmail} from "./DataValidator";
import CryptoJS from 'crypto-js';

export function isLoggedIn(){
    let loggedIn = getDataLocalStorage(appLocalStoargeValues.loggedIn)
    if(loggedIn === null){
        saveDataLocalStorage(appLocalStoargeValues.loggedIn, false)
    }
    return getDataLocalStorage(appLocalStoargeValues.loggedIn)
}


export function navigateTo(route){
    window.location = route
}

export function getLanguage(){
    let language = getDataLocalStorage(appLocalStoargeValues.language)
    if(language === null){
        return "nl";
    }else{
        return language
    }
}

export function hasAValue(value) {
    if (value !== null && value !== undefined) {
        return true
    } else {
        return false
    }
}

export function dateFormat(localDate) {
     let date =  new Date(Date.parse(localDate))
    return date.toLocaleDateString("nl")
}

export function filterOnlineDiscountBasedOnCategory(list, categoriesList) {
    console.log("Hier ")
    let newList = []
    newList = list.filter(element1 => {
        return element1.category_ids.some(element2 => {
            return categoriesList.includes(element2);
        });
    })
    newList.sort((a, b) => (a.advertiser.name.toString().toLowerCase() > b.advertiser.name.toString().toLowerCase()) ? 1 : -1);

    return newList
}


export function formValidator(element, dataForm) {
    if(element.toString().toLowerCase().includes("email")){
        console.log("validateEmail", validateEmail(dataForm[element].value), dataForm[element].value)
        return {'value': dataForm[element].value, 'valid': validateEmail(dataForm[element].value), 'error_message': "Email is not valid"}
    }
}

export function getBackgroundImageJobs(content) {
    let contentString = JSON.stringify(content);
    const randomNumber = Math.floor(Math.random() * 10) + 1;

    if (contentString.toLowerCase().includes("administrative")) {
        return joppStockImagesAdministrative[randomNumber];

    } else if (contentString.toLowerCase().includes("horeca")) {
        return joppStockImagesCateringIndustry[randomNumber];

    } else if (contentString.toLowerCase().includes("delivery")) {
        return joppStockImagesDelivery[randomNumber];

    } else if (contentString.toLowerCase().includes("marketing") || contentString.toLowerCase().includes("sales")) {
        return joppStockImagesMarketingAndSales[randomNumber];

    } else if (contentString.toLowerCase().includes("retail")) {
        return joppStockImagesRetail[randomNumber];

    } else if (contentString.toLowerCase().includes("customer") || contentString.toLowerCase().includes("service")) {
        return joppStockImagesCustomerService[randomNumber];

    } else if (contentString.toLowerCase().includes("logistics")) {
        return joppStockImagesLogistics[randomNumber];

    } else {
        return joppStockImagesOthers[randomNumber];
    }
}

export function getCityToShow(cityId, cities) {
    return cities.find(item => item.id === cityId);

}

export function generateSignature(body) {
    let timestamp = new Date().getTime().toString();
    const key = timestamp + process.env.REACT_APP_KNAEK_API_KEY;
    return {
        signature: CryptoJS.HmacSHA256(JSON.stringify(body), key).toString(),
        timestamp: timestamp
    };
}


export function filterSnipTechOnlineDiscountBasedOnCategory(list, categoriesList) {
    let newList = []
    newList = list.filter(element1 => {
        return element1?.categoryIds?.some(element2 => {
            let parsToNumb = parseInt(element2) || 0
            return categoriesList.includes(parsToNumb);
        });
    })

    newList.sort((a, b) => (a.title.toString().toLowerCase() > b.title.toString().toLowerCase()) ? 1 : -1);

    return newList
}

export function filterSnipTechOnlineDiscountBasedOnWebWeight(list, categoriesList) {
    return list
        .filter(element1 =>
            element1?.webWeight?.some(element2 =>
                categoriesList.includes(parseInt(element2) || 0)
            )
        )
        .sort((a, b) => {
            const webWeightA = parseInt(a.webWeight) || 0;
            const webWeightB = parseInt(b.webWeight) || 0;

            // Prioritize by weight
            if (webWeightA <= 70 && webWeightB > 70) return -1;
            if (webWeightA > 70 && webWeightB <= 70) return 1;

            // If weights are equivalent or both within the same range, sort by title
            return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
        });
}

export function calculateTheYearsFrom2009() {
let startYear = 2010
let currentYear = new Date().getFullYear()
    return currentYear - startYear
}

export function theNewYearOfKnaek() {
    let date = new Date();
    let currentYear = date.getFullYear();
    let currentMonth = date.getMonth();

    if (currentMonth >= 0 && currentMonth <= 6) {
        return currentYear;
    } else {
        return currentYear+1;
    }
}
